.activity-input-wrapper {
    margin-bottom: 0px;
  }

  .input {
    text-align: center;
    width: 200px
  }

  .activity-label :hover {
    color: white;
  }
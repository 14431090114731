.element {
    margin-bottom: 10px;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
  }

.input {
  text-align: center;
}

.input-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.input-wrapper {
  margin-bottom: 10px;
}

.time-label :hover {
  color: white;
}
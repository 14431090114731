@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  height: 50vh;
  border-radius: 50px;
  box-shadow: 0px 15px 30px black;
  max-width: 500px;
  margin: 0 auto;
  background-color: #d64040;
  border: 4px solid white;
  transition: box-shadow 0.3s;
}

.container:hover {
  box-shadow: 0px 30px 60px black;
}

.title :hover {
  color: white;

}

.activity-input {
  width: 200px;
  margin-bottom: 10px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.time-input {
  width: 200px;
  margin-bottom: 10px;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

.result {
  margin-top: 20px;
  font-size: 24px;
}

.calculate-button {
  background-color: #0056b3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid white;
}

.calculate-button:hover {
  background-color: white;
  color: black;
  border: 2px solid black;
}

.time-label.hover,
.time-label:focus-within {
  color: white;
}

.activity-input-wrapper:hover .activity-label,
.activity-input-wrapper:focus-within .activity-label {
  color: white;
}

.hour-wrapper:hover .time-label,
.hour-wrapper:focus-within .time-label {
  color: white;
}

.minute-wrapper:hover .time-label,
.minute-wrapper:focus-within .time-label {
  color: white;
}

body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  background-color: #17578f;
}

@media (max-height: 380px) {
  .container {
    padding: 5px;
    max-width: 100px;
    min-height: 300px;
  }

  .container.result-active {
    padding: 30px;
    min-height: 450px;
  }
}


@media (max-width: 600px) {
  
  .container {
    padding: 5px; 
    max-width: 100px;
    min-height: 300px;
  }

  .container.result-active {
    padding: 30px;
    min-height: 400px;
  }

  .title {
    padding-top: 0;
    margin-top: 0;
    width: 300%
  }

  h1 {
    padding-bottom: 0px;
    text-align: center;
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.5em;
  }

  label, input, select, button {
    font-size: 0.8em;
  }

  .step-count {
    font-size: 1.5em; 
  }


  .activity-input {
    padding: 0%;
  }

  button {
    width: 200%;
  }

  .result-wrapper {
    width: 150%;
  }
}
